import { render, staticRenderFns } from "./ActivityDocuments.vue?vue&type=template&id=01116567&scoped=true&"
import script from "./ActivityDocuments.vue?vue&type=script&lang=js&"
export * from "./ActivityDocuments.vue?vue&type=script&lang=js&"
import style0 from "./ActivityDocuments.vue?vue&type=style&index=0&id=01116567&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01116567",
  null
  
)

export default component.exports