<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container documents">
            <h2>Документы и издания</h2>
            <tricolor-line class="tricolor_line"/>

            <ul class="documents__type-list">
              <li v-for="(type, key) of documents_type" class="documents__type-item"
                  :class="{'documents__active' : isActiveItem === key}"
                  :key="`type${key}`"
                  @click="ActiveItem(type, key)">
                {{ type }}
              </li>
            </ul>

            <h3>{{ documents_type[isActiveItem] }}</h3>
          <div v-if="thisYear &&  statusSelectView" class="documents__filter">
            <custom-select :title="thisYear" key_name="title" class="documents__filter-select"
                           @InputsContent="setInclusion"
                           :list='dateSelect'
                           placeholder="год"/>
            <a href="https://old.oprf.ru/documents/497/2628/" target="_blank" class="documents__filter-link">Архив до 2020 года</a>
          </div>
          <div class="documents-block">
            <documents-list v-for="item in arrayRes"
                            :key="`list${item.id}`"
                            class="documents__list"
                            :doc="item"
            />
          </div>
        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import DocumentsList from '@/components/ChamberNormativeBaseDocuments'
import TricolorLine from '@/components/TricolorLine'
import uniq from '@/methods/uniq'
import CustomSelect from '@/components/forms/CustomSelect'

export default {
  name: 'ActivityDocuments',
  data () {
    return {
      isActiveItem: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/actual'
        },
        {
          title: 'Документы'
        }
      ],
      documents_type: [],
      arrayRes: [],
      systemTagList: ['/activity_documents'],
      thisYear: '',
      dateSelect: [],
      statusSelectView: true
    }
  },
  mounted () {
    // for (let i = 2020; i <= new Date().getFullYear(); i++) this.dateSelect.push({ title: i })
    this.setDocuments()
    document.title = 'Документы'
  },
  watch: {
    arrayRes (value) {
      if (this.documents_type.length === 0) {
        this.arrayRes = value
        let array = []
        let yearsArray = []
        value.map(item => {
          item.tagList.map(data => {
            if (!Number.isInteger(parseInt(data))) {
              array.push(data)
            } else {
              yearsArray.push(parseInt(data))
            }
          })
        })
        array = uniq(array)
        yearsArray = uniq(yearsArray)
        yearsArray.sort()
        this.thisYear = yearsArray[yearsArray.length - 1].toString()
        yearsArray.map(item => {
          this.dateSelect.push({ title: item })
        })
        console.log(yearsArray)
        this.documents_type = array
      }
    },
    documents_type (value) {
      if (this.documents_type.length !== 0) {
        this.setDocuments(this.thisYear, this.documents_type[this.isActiveItem])
      }
    }
  },
  methods: {
    ActiveItem (name, i) {
      console.log(name)
      if (name === 'Ежегодные доклады о состоянии гражданского общества в России') {
        this.setDocuments('', name)
        this.statusSelectView = false
      } else {
        this.statusSelectView = true
        this.setDocuments(this.thisYear, name)
      }
      this.isActiveItem = i
    },
    setInclusion (val) {
      this.thisYear = val.title
      this.setDocuments(this.documents_type[this.isActiveItem], val.title)
    },
    async setDocuments (year, title) {
      const formdata = new FormData()
      const dataSearch = []
      if (year && title !== 'Ежегодные доклады о состоянии гражданского общества в России') {
        this.statusSelectView = true
        dataSearch.push(year)
      } else {
        this.statusSelectView = false
      }
      if (title) dataSearch.push(title)
      if (dataSearch.length !== 0) {
        formdata.append('tagList', JSON.stringify(dataSearch))
      }
      formdata.append('systemTagList', JSON.stringify(this.systemTagList))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      console.log(await res)
      this.arrayRes = res.data.documents
    }
  },
  components: {
    BreadCrumb,
    DocumentsList,
    TricolorLine,
    CustomSelect
  }
}
</script>

<style lang="scss" scoped>

.documents-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1.875rem;
}

.white-container{
    width: 61.4rem;
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}
h3{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.375rem;

  color: #1F3245;
}
.tricolor_line {
  margin-bottom: 2rem;
}
.documents {
  &__type-list {
    margin-left: 1.375rem;
    margin-bottom: 3.125rem;
  }
  &__type-item {
    list-style: disc;
    margin-bottom: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #246CB7;

    cursor: pointer;
  }
  &__filter {
    margin-bottom: 1.875rem;
    display: inline-flex;
  }

  &__filter-select {
    width: 7.5rem;
  }

  &__filter-link {
      padding-left: 1.375em;
      display: flex;
      align-items: center;

      font-size: .75rem;
      line-height: 1.125rem;
      color: #003E78;
      opacity: 0.6;

    &::after {
      content: '';
      margin-left: 0.75rem;
      width: 0.7rem;
      height: 0.7rem;
      background-image: url('../../assets/icons/icon_link.svg');
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  &__list {
    margin-bottom: 2.5rem;
  }
  &__active {
    font-weight: 600;
  }
}

@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }

}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
}
</style>
